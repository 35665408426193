import * as React from 'react';

interface TailwindPrimitiveProps {
  className?: string;
}

export const SectionHeader: React.FC<TailwindPrimitiveProps> = ({ className = '', children }) => (
  <h2 className={`text-title-primary text-xl sm:text-2xl md:text-4xl font-semibold ${className}`}>
    {children}
  </h2>
);
