import * as React from 'react';
import styled from 'styled-components';
import { colors } from 'utils/colors';
import { routes } from 'models/routes';
import { NavLink, Logo } from 'components';

interface HeaderProps {
  scrollTop: boolean;
}

const StyledHeader: React.FC<HeaderProps> = ({ scrollTop, children }) => (
  <header
    className={`z-50 fixed top-0 flex justify-center w-full h-16 md:h-16 px-4 transition duration-150 ease-in-out ${
      scrollTop ? 'bg-transparent' : 'bg-black-header'
    }`}
  >
    {children}
  </header>
);

export const Header = () => {
  const [scrollTop, setScrollTop] = React.useState(true);
  const [currentNavId, setCurrentNavId] = React.useState();

  React.useEffect(() => {
    const onScrollHandler = () => {
      setScrollTop(!window.scrollY);
    };

    window.addEventListener('scroll', onScrollHandler);

    return () => {
      window.removeEventListener('scroll', onScrollHandler);
    };
  });

  const renderNavLinks = ({ routes, ids }) => {
    return ids.map((id, index) => (
      <NavLink
        key={`nav-link-${id}`}
        className={index !== ids.length - 1 ? 'mr-4' : ''}
        href={routes[id].name}
        isActive={currentNavId === routes[id].name.slice(1)}
        onAnchorHandler={(elementId) => {
          setCurrentNavId(elementId);
        }}
      >
        {routes[id].label}
      </NavLink>
    ));
  };

  return (
    <StyledHeader scrollTop={scrollTop}>
      <div className="flex justify-between items-center w-full max-w-screen-xl">
        <nav className="flex items-center">
          <Logo className="mr-4" size={30} color={colors.WHITE} href="/" />
          {renderNavLinks(routes)}
        </nav>
      </div>
    </StyledHeader>
  );
};
