import { normalizeData } from 'utils/normalizer';

interface Route {
  id: string;
  name: string;
  label: string;
}

const routeList: Route[] = [
  {
    id: 'about',
    name: '#about',
    label: 'About',
  },
  {
    id: 'services',
    name: '#services',
    label: 'Services',
  },
  {
    id: 'projects',
    name: '#projects',
    label: 'Projects',
  },
  {
    id: 'contact',
    name: '#contact',
    label: 'Contact',
  },
];

export const routes = normalizeData(routeList, 'routes');
