import * as React from 'react';
import styled from 'styled-components';

export const PageLayout = styled.main`
  background: #fff;
  height: 100vh;
  width: 100%;
  /* display: grid;
  grid-template-rows: 65px auto;
  grid-template-columns: auto; */
`;
