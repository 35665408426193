import * as React from 'react';
import styled from 'styled-components';
import { SectionHeader } from 'components/SectionHeader';
import { EXPRESS_SCRIPTS_IMAGE } from './data';
import { OpenLink } from 'icons/OpenLink';
import { colors } from 'utils/colors';
import { SectionDescription } from 'components/SectionDescription';

interface Project {
  id: string;
  backgroundUrl: string;
  url: string;
  label?: string;
  backgroundSize?: string;
  isInternal?: boolean;
}

const projects: Project[] = [
  {
    id: 'chevrolet-logo',
    backgroundUrl: 'chevrolet-logo-v2.png',
    url: 'https://www.chevrolet.com',
  },
  {
    id: 'gmc-logo',
    backgroundUrl: 'gmc-logo.png',
    url: 'https://www.gmc.com',
  },
  {
    id: 'cadillac-logo',
    backgroundUrl: 'cadillac-logo.png',
    url: 'https://www.cadillac.com',
    backgroundSize: '40%',
  },
  {
    id: 'buick-logo',
    backgroundUrl: 'buick-logo.png',
    url: 'https://www.buick.com',
  },
  {
    id: 'cloudflare-logo',
    backgroundUrl: 'cloudflare-logo.svg',
    url: 'https://dash.cloudflare.com',
  },
  {
    id: 'thd-pro-logo',
    backgroundUrl: 'homedepot-logo.svg',
    url: 'https://www.homedepot.com',
  },
  {
    id: 'express-scripts-logo',
    backgroundUrl: EXPRESS_SCRIPTS_IMAGE,
    url: 'https://www.express-scripts.com',
    isInternal: true,
  },
  {
    id: 'cs-disco-logo',
    backgroundUrl: 'disco-logo.svg',
    url: 'https://www.csdisco.com',
    isInternal: true,
  },
];

const SectionWrapper = styled.section`
  background: #f1f1f1;
  padding: 2rem 1rem 1rem;

  @media screen and (min-width: 768px) {
    padding: 4rem 1rem 3rem;
  }
`;

const SectionBody = styled.div`
  max-width: 1200px;
  margin: 0 auto 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 768px) {
    margin: 0 auto 6rem;
  }
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  width: 100%;
  max-width: 900px;

  @media screen and (min-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
  }
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2.5rem;
  }
`;

interface CardProps {
  backgroundUrl: string;
  backgroundSize?: string;
}

const Card = styled.a<CardProps>`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 100%;
  position: relative;
  background: ${({ backgroundUrl }) => `url(${backgroundUrl})`};
  background-color: #fff;
  background-size: ${({ backgroundSize = '70%' }) => backgroundSize};
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 0 5px 1px rgba(200, 200, 200);

  &:hover {
    box-shadow: 0 0 8px 1px rgba(150, 150, 150);
    transition: all 0.15s ease-in-out;
  }
`;

const InfoTag = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  background: ${colors.SECONDARY};
  color: #fff;
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: serif;
  font-weight: 600;
`;

const withHover = (BaseComponent) => (props) => {
  const [isHovered, setHoverState] = React.useState(false);
  const onMouseEnter = () => setHoverState(true);
  const onMouseLeave = () => setHoverState(false);
  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <BaseComponent {...props} hovered={isHovered}>
        {props.children({ isHovered })}
      </BaseComponent>
    </div>
  );
};

const NewCard = withHover(Card);

export const Projects = () => {
  const renderCards = (projects) => {
    return projects.map(
      ({ backgroundUrl, url, id, label = 'View Site', backgroundSize, isInternal = false }) => (
        <NewCard
          key="id"
          backgroundUrl={backgroundUrl}
          href={url}
          target="_blank"
          rel="noreferrer"
          backgroundSize={backgroundSize}
        >
          {({ isHovered }) => {
            return (
              <>
                {isInternal && <InfoTag className="absolute top-4 right-4">i</InfoTag>}

                <div
                  className={`flex items-center justify-center absolute bottom-0 right-0 mb-8 mr-10 transition duration-150 ease-in-out ${
                    isHovered ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  <span className={isHovered ? 'text-purple-dark mr-2' : 'text-purple mr-2'}>
                    {label}
                  </span>
                  <OpenLink color={isHovered ? colors.DARK_PURPLE : colors.PRIMARY} size={20} />
                </div>
              </>
            );
          }}
        </NewCard>
      ),
    );
  };

  return (
    <SectionWrapper id="projects">
      <SectionBody>
        <SectionHeader>Projects</SectionHeader>
        <SectionDescription className="mb-4 md:mb-12 md:text-center">
          Need someone with experience developing enterprise-grade web applications? These are some
          of the companies I've worked with in the past
        </SectionDescription>
        <CardContainer>{renderCards(projects)}</CardContainer>
        <div className="flex items-center mt-12 md:mt-16">
          <InfoTag className="relative mr-2">i</InfoTag>
          <span className="inline-block italic flex-1 text-sm md:text-base">
            Disclaimer: worked on proprietary internal application for respective company
          </span>
        </div>
      </SectionBody>
    </SectionWrapper>
  );
};
