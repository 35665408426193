import * as React from 'react';

interface SvgProps {
  size: number;
  color: string;
}

export const WrenchIcon: React.FC<SvgProps> = ({ size, color, ...props }) => (
  <svg width={size} height={size} viewBox="0 0 300 300" fill={color} {...props}>
    <g>
      <path
        d="M63.946,118.649c5.727,0,11.353-0.897,16.773-2.625l32.214,32.192l35.279-35.272l-32.199-32.227
		c6.207-19.396,1.107-40.888-13.359-55.349C92.356,15.067,78.647,9.396,64.091,9.396c-8.133,0-16.288,1.843-23.605,5.332
		c-1.104,0.526-1.877,1.552-2.084,2.755c-0.201,1.203,0.186,2.424,1.045,3.291l33.894,33.902c2.501,2.499,3.881,5.817,3.881,9.339
		c0,3.531-1.382,6.843-3.881,9.342c-4.979,4.994-13.684,4.994-18.672-0.013L20.763,39.451c-0.869-0.869-2.118-1.25-3.287-1.048
		c-1.206,0.207-2.229,0.98-2.755,2.084c-9.958,20.868-5.69,45.858,10.641,62.177C35.666,112.968,49.368,118.649,63.946,118.649z"
      />
      <path
        d="M236.074,181.348c-5.739,0-11.374,0.903-16.798,2.635l-35.631-35.634l-35.303,35.297l35.641,35.64
		c-6.194,19.39-1.098,40.876,13.359,55.346c10.301,10.301,24.01,15.973,38.565,15.973c8.137,0,16.291-1.847,23.608-5.335
		c1.104-0.525,1.877-1.552,2.084-2.755c0.207-1.203-0.186-2.425-1.045-3.291l-33.893-33.909c-2.496-2.493-3.875-5.805-3.878-9.327
		c0-3.528,1.379-6.84,3.884-9.342c4.995-4.998,13.681-4.995,18.666,0.003l33.899,33.893c0.866,0.863,2.075,1.247,3.291,1.048
		c1.203-0.21,2.23-0.98,2.755-2.084c9.958-20.865,5.693-45.852-10.638-62.174C264.339,187.029,250.64,181.348,236.074,181.348z"
      />
      <path
        d="M221.571,100.988c0,0,5.897,5.165,10.759,2.752c5.495-2.765,20.976,12.497,25.915,16.068
		c0.016,0.012,0.05,0.012,0.108,0.04l-2.7,2.712c-3.566,3.525-3.563,9.281-0.009,12.812c3.556,3.541,9.274,3.538,12.831,0
		l21.378-21.35c3.532-3.55,3.535-9.296-0.006-12.84c-3.528-3.532-9.283-3.532-12.824,0.003l-3.56,3.557
		c-2.95-4.063-7.852-10.988-7.23-13.282c2.607-10.047-17.875-33.779-17.875-33.779C197.49,6.823,163.513,7.979,147.349,13.537
		c-6.488,2.232-5.285,3.646,1.454,4.833c49.04,8.56,55.123,37.48,46.81,48.638c-3.197,4.308,1.466,9.509,1.466,9.509l1.753,1.741
		l-82.803,82.788c1.123,0.677,2.214,1.447,3.176,2.412l13.929,13.916c0.946,0.949,1.719,2.032,2.39,3.154l82.804-82.791
		L221.571,100.988z"
      />
      <path
        d="M113.632,169.025c-3.056-3.059-8.257-3.256-11.526-0.417l-92.019,82.497c-3.284,2.836-3.467,7.663-0.405,10.725
		l25.064,25.046c3.064,3.062,7.882,2.891,10.715-0.375l82.522-92.046c2.823-3.266,2.644-8.449-0.421-11.507L113.632,169.025z"
      />
    </g>
  </svg>
);
