import * as React from 'react';

interface SvgProps {
  size: number;
  color: string;
}

export const AccessibilityIcon: React.FC<SvgProps> = ({ size, color, ...props }) => (
  <svg width={size} height={size} viewBox="0 -2 26 30" fill={color} {...props}>
    <g>
      <path
        style={{ fill: color }}
        d="M13,0C5.82,0,0,5.82,0,13s5.82,13,13,13c7.178,0,13-5.82,13-13S20.178,0,13,0z M13,24.053
		C6.896,24.053,1.947,19.104,1.947,13S6.896,1.947,13,1.947S24.053,6.896,24.053,13S19.104,24.053,13,24.053z M13,3.999
		c-4.972,0-9.001,4.029-9.001,9.001S8.028,22,13,22s8.999-4.028,8.999-9S17.972,3.999,13,3.999z M13,5.693
		c0.994,0,1.8,0.806,1.8,1.8c0,0.995-0.806,1.8-1.8,1.8s-1.8-0.805-1.8-1.8C11.2,6.499,12.006,5.693,13,5.693z M18.097,10.704
		c-0.469,0.051-2.08,0.13-2.712,0.159c-0.631,0.031-1.263-0.033-1.263,1.054c0,1.986,0.632,5.06,1.233,6.685
		c0.547,1.477,0.241,1.232-0.03,1.383s-0.662-0.031-0.903-0.692c-0.239-0.661-0.752-2.618-0.871-3.128
		c-0.122-0.513-0.211-1.054-0.513-1.054c-0.3,0-0.39,0.301-0.48,0.903c-0.091,0.602-0.782,3.069-0.993,3.43
		c-0.209,0.36-0.271,0.571-0.753,0.571c-0.48,0-0.45-0.481-0.302-0.903c0.151-0.42,1.387-4.456,1.444-7.044
		c0.022-0.872-0.118-1.143-1.112-1.143c-0.993,0-3.039-0.24-3.039-0.24s-0.752-0.03-0.722-0.572S7.622,9.66,7.924,9.69
		c0,0,3.073,0.298,5.059,0.298s5.261-0.328,5.261-0.328s0.571-0.029,0.661,0.392C18.995,10.473,18.623,10.647,18.097,10.704z"
      />
    </g>
  </svg>
);
