import * as React from 'react';

interface SvgProps {
  size: number;
  color: string;
}

export const AtomIcon: React.FC<SvgProps> = ({ size, color, ...props }) => (
  <svg width={size} height={size} viewBox="0 0 31.483 31.482" fill={color} {...props}>
    <g>
      <g>
        <path
          d="M26.642,15.741c2.475-2.526,3.976-4.964,4.233-6.884c0.151-1.122-0.102-2.058-0.753-2.781
			c-0.796-0.886-2.091-1.334-3.847-1.334c-1.37,0-2.976,0.272-4.775,0.81C20.098,2.02,18.007,0,15.741,0s-4.356,2.02-5.758,5.552
			c-1.8-0.538-3.406-0.81-4.775-0.81c-1.757,0-3.051,0.449-3.848,1.334C0.709,6.8,0.456,7.735,0.607,8.856
			c0.257,1.919,1.759,4.358,4.234,6.885c-2.476,2.526-3.977,4.965-4.234,6.884c-0.153,1.122,0.101,2.057,0.753,2.781
			c0.797,0.886,2.091,1.335,3.848,1.335c1.368,0,2.974-0.272,4.775-0.812c1.402,3.533,3.493,5.553,5.758,5.553
			c2.266,0,4.357-2.021,5.759-5.553c1.802,0.538,3.407,0.812,4.775,0.812c1.756,0,3.051-0.449,3.847-1.334
			c0.651-0.724,0.905-1.66,0.753-2.782C30.618,20.706,29.117,18.268,26.642,15.741z M22.955,14.819
			c0.361,0.306,0.711,0.615,1.045,0.922c-0.334,0.308-0.684,0.616-1.045,0.922c0.01-0.306,0.015-0.612,0.015-0.922
			S22.964,15.124,22.955,14.819z M28.757,8.902c-0.206,1.525-1.489,3.558-3.622,5.74c-0.714-0.659-1.49-1.315-2.31-1.955
			c-0.176-1.91-0.516-3.694-1.011-5.31c3.235-0.965,5.721-0.92,6.592,0.047C28.724,7.776,28.842,8.273,28.757,8.902z M22.826,18.795
			c0.819-0.641,1.595-1.296,2.31-1.955c2.133,2.182,3.416,4.215,3.622,5.74c0.085,0.629-0.033,1.126-0.352,1.479
			c-0.869,0.967-3.355,1.012-6.591,0.047C22.31,22.49,22.65,20.706,22.826,18.795z M21.042,20.117
			c-0.18,1.223-0.435,2.387-0.759,3.47c-0.925-0.338-1.88-0.741-2.849-1.2c0.989-0.558,1.874-1.098,2.694-1.642
			C20.44,20.537,20.743,20.328,21.042,20.117z M15.741,29.391c-1.486,0-2.949-1.574-4.034-4.328c1.329-0.48,2.685-1.071,4.035-1.758
			c1.346,0.686,2.701,1.275,4.034,1.758C18.69,27.816,17.227,29.391,15.741,29.391z M11.354,20.744
			c0.815,0.542,1.7,1.082,2.693,1.642c-0.969,0.459-1.924,0.862-2.849,1.2c-0.325-1.083-0.58-2.248-0.759-3.471
			C10.738,20.328,11.043,20.537,11.354,20.744z M10.439,11.367c0.18-1.224,0.435-2.388,0.759-3.471
			c0.928,0.339,1.884,0.742,2.85,1.2c-0.987,0.557-1.872,1.096-2.694,1.643C11.043,10.946,10.738,11.155,10.439,11.367z
			 M15.741,2.092c1.487,0,2.95,1.574,4.035,4.328c-1.333,0.482-2.688,1.073-4.035,1.758c-1.348-0.686-2.704-1.277-4.035-1.759
			C12.792,3.666,14.254,2.092,15.741,2.092z M20.129,10.739c-0.819-0.544-1.704-1.084-2.693-1.643c0.97-0.46,1.926-0.863,2.849-1.2
			c0.325,1.082,0.58,2.246,0.76,3.469C20.743,11.154,20.44,10.945,20.129,10.739z M21.36,15.741c0,0.722-0.029,1.465-0.088,2.269
			c-0.687,0.52-1.379,1.01-2.058,1.461c-1.105,0.736-2.273,1.432-3.473,2.072c-1.198-0.639-2.365-1.335-3.473-2.072
			c-0.682-0.453-1.374-0.945-2.059-1.463c-0.059-0.803-0.088-1.546-0.088-2.268c0-0.721,0.029-1.465,0.088-2.268
			c0.688-0.52,1.38-1.011,2.059-1.462c1.105-0.735,2.273-1.432,3.473-2.073c1.196,0.638,2.363,1.335,3.473,2.072
			c0.679,0.451,1.371,0.943,2.058,1.461C21.331,14.276,21.36,15.02,21.36,15.741z M8.527,16.663
			c-0.357-0.304-0.707-0.612-1.044-0.922c0.335-0.31,0.685-0.617,1.044-0.922c-0.008,0.305-0.013,0.613-0.013,0.922
			S8.519,16.357,8.527,16.663z M8.655,12.688c-0.819,0.64-1.594,1.296-2.307,1.954c-2.134-2.183-3.417-4.215-3.624-5.741
			C2.64,8.273,2.758,7.776,3.075,7.424C3.52,6.929,4.386,6.667,5.578,6.667c1.135,0,2.51,0.239,4.088,0.71
			C9.172,8.992,8.832,10.777,8.655,12.688z M2.724,22.581c0.206-1.524,1.49-3.558,3.624-5.741c0.712,0.657,1.488,1.312,2.307,1.955
			c0.177,1.911,0.517,3.695,1.012,5.311c-3.235,0.965-5.721,0.918-6.592-0.047C2.758,23.706,2.64,23.209,2.724,22.581z"
        />
        <circle cx="15.741" cy="15.741" r="3.278" />
      </g>
    </g>
  </svg>
);
