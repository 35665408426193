export const normalizeData = (arr: any[], key) => {
  const base = {
    [key]: {},
    ids: [],
  };
  return arr.reduce((entity, element) => {
    entity[key][element.id] = { ...element };
    entity.ids.push(element.id);
    return entity;
  }, base);
};
