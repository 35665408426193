import * as React from 'react';
import styled from 'styled-components';
import { SectionHeader } from 'components/SectionHeader';
import { SectionDescription } from 'components/SectionDescription';
import { colors } from 'utils/colors';
import { FacebookLogo } from 'icons/FacebookLogo';
import { LinkedInLogo } from 'icons/LinkedInLogo';
import { GithubLogo } from 'icons/GithubLogo';
import { ButtonV1 } from 'components/ButtonV1';

const SectionWrapper = styled.section`
  background: ${colors.DARK};
  background: #252630;
  padding: 2rem 1rem 2rem;
  position: relative;

  &::after {
    display: none;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    border-left: 50vw solid transparent;
    border-right: 50vw solid transparent;
    border-top: 80px solid #f1f1f1;

    @media screen and (min-width: 768px) {
      display: block;
    }
  }

  @media screen and (min-width: 768px) {
    padding: 8rem 1rem 3rem;
  }
`;

const SectionBody = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  max-width: 750px;
`;

const FormInput = styled.input`
  background: rgba(52, 67, 66, 0.2);
  width: 100%;
  padding: 0.75rem 0 0.75rem 0.5rem;
  margin-bottom: 1.5rem;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.7);

  &:focus,
  &:active {
    border-color: ${colors.SECONDARY};
    outline: none;
  }
`;

const FormTextArea = styled.textarea`
  background: rgba(52, 67, 66, 0.2);
  width: 100%;
  height: 120px;
  padding: 0.75rem 0 0.75rem 0.5rem;
  margin-bottom: 1.5rem;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.7);

  &:focus,
  &:active {
    border-color: ${colors.SECONDARY};
    outline: none;
  }

  @media screen and (min-width: 768px) {
    height: 250px;
  }
`;

const FormLabel = styled.label`
  display: block;
  text-align: left;
  color: #00a698;
  color: #fff;
  color: #e2e8f0;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0.5rem;
  cursor: pointer;

  @media screen and (min-width: 768px) {
    font-size: 18px;
  }
`;

const StyledFooter = styled.footer`
  display: flex;
  justify-content: center;
  background: ${colors.DARK};
  padding: 1rem;
  width: 100%;
`;

const FooterBody = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const IconContainer = styled.a`
  display: block;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border-radius: 3px;
  border: 1px solid #999;
  padding: 0.5rem;
  transition: all 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    margin-top: -1px;
    border-color: ${colors.PRIMARY};
    background: ${colors.PRIMARY};
  }

  &:not(:last-child) {
    margin-right: 1rem;
  }

  @media screen and (min-width: 768px) {
    width: 2.5rem;
    height: 2.5rem;
    &:not(:last-child) {
      margin-right: 1.5rem;
    }
  }
`;

export const Contact = () => {
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    await fetch('/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, email, message }),
    });
  };

  const onChangeHandler = (callback) => (event) => {
    callback(event.target.value);
  };

  return (
    <>
      <SectionWrapper id="contact">
        <SectionBody>
          <SectionHeader>Contact</SectionHeader>
          <SectionDescription className="text-white text-center mb-4 md:mb-8">
            Have a question or want to work together? Send me a message!
          </SectionDescription>
          <FormContainer onSubmit={onSubmitHandler}>
            <div className="flex w-full flex-wrap">
              <div className="flex flex-col w-full md:w-1/2 md:pr-8 mb-2 md:mb-0">
                <FormLabel htmlFor="name">Name</FormLabel>
                <FormInput
                  id="name"
                  type="text"
                  placeholder="Hank Hill"
                  value={name}
                  onChange={onChangeHandler(setName)}
                />
              </div>
              <div className="flex flex-col w-full md:w-1/2">
                <FormLabel htmlFor="email">Email</FormLabel>
                <FormInput
                  id="email"
                  type="email"
                  placeholder="hank.hill@gmail.com"
                  value={email}
                  onChange={onChangeHandler(setEmail)}
                  required={true}
                />
              </div>
            </div>
            <FormLabel htmlFor="message">Message</FormLabel>
            <FormTextArea
              id="message"
              placeholder="Enter your message here..."
              value={message}
              onChange={onChangeHandler(setMessage)}
            />
            <ButtonV1 variant="PRIMARY" className="self-end" width="150px" type="input" />
          </FormContainer>
        </SectionBody>
      </SectionWrapper>
      <StyledFooter>
        <FooterBody>
          <div className="order-2">
            <span className="block mb-2 text-white">
              <span>Richard Nguyen</span>
              <span className="mx-2">•</span>
              <span className="text-title-primary">Copyright 2020</span>
            </span>
            <div className="text-gray-600 whitespace-no-wrap text-sm">
              <span>Icon design by </span>
              <a
                className="italic hover:border-solid hover:border-0 hover:border-b hover:border-gray-600"
                href="https://www.flaticon.com/authors/pixel-perfect"
                title="Pixel perfect"
              >
                Pixel perfect
              </a>
            </div>
          </div>
          <div className="order-1 md:order-3 flex items-center mb-4 md:mb-0">
            <IconContainer
              href="https://www.linkedin.com/in/richard-nguyen-b7a553102/"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedInLogo size={'100%'} color={colors.WHITE} />
            </IconContainer>
            <IconContainer
              href="https://www.facebook.com/richard.nguyen.31586/"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookLogo size={'100%'} color={colors.WHITE} />
            </IconContainer>
            <IconContainer href="https://github.com/rnguyen17" target="_blank" rel="noreferrer">
              <GithubLogo size={'100%'} color={colors.WHITE} />
            </IconContainer>
          </div>
        </FooterBody>
      </StyledFooter>
    </>
  );
};
