import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { colors } from 'utils/colors';
import { WrenchIcon } from 'icons/WrenchIcon';
import { AtomIcon } from 'icons/AtomIcon';
import { CodingIcon } from 'icons/CodingIcon';
import { useMediaQuery } from 'utils/useMediaQuery';

const LANGUAGES = ['HTML5', 'CSS3', 'JavaScript', 'TypeScript', 'Bash', 'Python', 'Java'];

const FRAMEWORKS = [
  'Node.js',
  'jQuery',
  'React/Redux',
  'Svelte',
  'Next.js',
  'Sapper',
  'MongoDB',
  'Redis',
];

const BUILD_TOOLS = [
  'Docker',
  'Kubernetes',
  'GCP',
  'TeamCity',
  'Jenkins',
  'Git',
  'Webpack',
  'Jest',
  'Cypress',
];

interface AnimatedCardProps {
  delay: number;
}

const AnimatedCard = styled.div<AnimatedCardProps>`
  transition: all 0.3s ease-in-out;
  transition-delay: ${({ delay = 0 }) => `${delay}ms`};

  &.fade-enter {
    opacity: 0;
    transform: translateY(100px);
  }
  &.fade-enter-active,
  &.fade-enter-done {
    opacity: 1;
    transform: translateY(0);
  }
`;

const CardListItem = styled.li`
  margin-bottom: 0.5rem;
  width: 33.3333%;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 960px) {
    margin-bottom: 1rem;
    width: 100%;
    font-size: 18px;
  }
`;

const skillsCardsData = [
  {
    Icon: CodingIcon,
    title: 'Languages',
    skills: LANGUAGES,
  },
  {
    Icon: AtomIcon,
    title: 'Frameworks',
    skills: FRAMEWORKS,
  },
  {
    Icon: WrenchIcon,
    title: 'Infrastructure & Testing',
    skills: BUILD_TOOLS,
  },
];

interface CardDataProps {
  Icon: React.FunctionComponent<any>;
  title: string;
  skills: string[];
}

export const SkillsCardsContainer = () => {
  const [showCards, toggleShowCards] = React.useState(false);
  const containerRef = React.createRef<HTMLDivElement>();

  const isDesktop = useMediaQuery('lg');
  const iconSize = isDesktop ? 40 : 20;

  React.useEffect(() => {
    const target = containerRef.current as Element;
    const onContainerIntersection = (entries: IntersectionObserverEntry[]) => {
      if (entries.length && entries[0] && entries[0].isIntersecting) {
        toggleShowCards(true);
      }
    };

    const containerObserver = new IntersectionObserver(onContainerIntersection, {
      threshold: 0.4,
    });
    containerObserver.observe(target);

    return () => {
      if (target) {
        containerObserver.unobserve(target);
      }
    };
  });

  const renderCards = (skillsCardsData: CardDataProps[]) => {
    return skillsCardsData.map(({ Icon, title, skills }: CardDataProps, index: number) => {
      return (
        <CSSTransition
          key={title}
          classNames="fade"
          mountOnEnter
          in={showCards}
          timeout={300 + 100 * index}
        >
          <AnimatedCard
            className="flex flex-col sm:flex-row lg:flex-col items-center py-4 md:py-10 px-4 h-full w-full bg-white shadow-lg rounded-lg"
            delay={100 * index}
          >
            <div className="flex flex-wrap sm:flex-col w-full sm:w-64 lg:w-full items-center sm:pr-4 border-0 sm:border-r border-bg-black lg:border-0 lg:pr-0">
              <Icon size={iconSize} color={colors.SECONDARY} />
              <h3 className="ml-2 sm:ml-0 sm:mt-2 sm:text-center text-black-text text-lg md:text-xl font-semibold">
                {title}
              </h3>
            </div>
            <hr className="hidden lg:block w-1/3 my-6" />
            <ul className="flex flex-wrap w-full pt-4 sm:pt-0 sm:pl-8 lg:w-1/3 lg:pl-0">
              {skills.map((skill) => (
                <CardListItem className="skill" key={skill}>
                  {skill}
                </CardListItem>
              ))}
            </ul>
          </AnimatedCard>
        </CSSTransition>
      );
    });
  };

  return (
    <div
      className="grid lg:grid-cols-3 gap-4 md:gap-8 mt-4 md:mt-12 md:min-h-570"
      ref={containerRef}
    >
      {renderCards(skillsCardsData)}
    </div>
  );
};
