import * as React from 'react';
import styled from 'styled-components';
import { colors } from 'utils/colors';
import { SectionHeader } from 'components/SectionHeader';
import { SkillsCardsContainer } from './SkillsCardsContainer';
import { SectionWrapper, SectionDescription } from 'components';
import { useMediaQuery } from 'utils/useMediaQuery';

const AboutWrapper = styled(SectionWrapper)`
  position: relative;

  &:after {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    width: 100vw;
    height: 27.5rem;
    background: ${colors.LIGHT_GREEN};
  }
`;

export const About = () => {
  const isDesktop = useMediaQuery('lg');
  let description = `Currently working at Cloudflare in Austin, TX, I have over 6 years of experience
  developing enterprise-level software.${
    isDesktop
      ? " When I'm not learning new technologies or building random stuff, I enjoy playing basketball and watching the Rockets."
      : ''
  }`;

  return (
    <AboutWrapper id="about" className="bg-green-light-background lg:bg-transparent">
      <div className="mx-auto max-w-screen-xl">
        <SectionHeader>Hey, I'm Richard Nguyen</SectionHeader>
        <SectionDescription>{description}</SectionDescription>
        <SkillsCardsContainer />
      </div>
    </AboutWrapper>
  );
};
