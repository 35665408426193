import * as React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { createGlobalStyle } from 'styled-components';
import { PageLayout } from '../components/PageLayout';
import { Header } from '../components/Header/Header';
import { Masthead } from '../components/Masthead/Masthead';
import { About } from 'modules/About';
import { Contact } from 'modules/Contact';
import { Featured } from 'modules/Featured';
import { Projects } from 'modules/Projects';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  
  body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    font-family: 'Raleway';
    font-size: 16px;
  }

  ul, li {
    list-style: none;
    margin: 0;
  }

  a {
    display: inline-block;
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
    margin: 0;
  }

  h1 {
    font-size: 60px;
  }

  h2 {
    font-size: 32px;
  }

  .no-js {
    visibility: hidden;
    opacity: 0;
  }
`;

interface DataShape {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
}

const App = ({ data }: DataShape) => {
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Pacifico|Raleway:400,600"
          rel="stylesheet"
        />
        <title>Richard Nguyen - Senior UI Engineer</title>
        <meta
          name="description"
          content="Portfolio site showcasing various web projects worked on by Richard Nguyen, a Senior Frontend Engineer based in Austin, TX."
        />
        <meta
          name="keywords"
          content="Richard Nguyen, Austin Senior UI Engineer, Austin Senior Frontend Engineer"
        />
        <meta name="author" content="Richard Nguyen" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <html lang="en" />
      </Helmet>
      <GlobalStyle />
      <PageLayout id="main">
        <Header />
        <Masthead />
        <About />
        <Featured />
        <Projects />
        <Contact />
      </PageLayout>
    </>
  );
};

export const data = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default App;
