import * as React from 'react';
import styled from 'styled-components';
import { colors } from 'utils/colors';
import { ButtonV1 } from 'components/ButtonV1';
import { NavLink } from 'components/NavLink';

const CircleGuy = styled.span`
  margin-left: 8px;
  display: flex;
  justify-content: center;
  height: 25px;
  width: 25px;
  line-height: 22px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 50%;
  color: ${colors.WHITE};
  transition: all 0.15s ease-out;
`;

const StyledNavLink = styled(NavLink)`
  display: inline-flex;
  align-items: center;
  margin: 1rem auto 0;

  @media (min-width: 600px) {
    margin: 6rem auto 0;
  }

  &::after {
    display: none;
  }

  &:hover {
    ${CircleGuy} {
      transform: rotateZ(90deg);
      border-color: ${colors.SECONDARY};
      color: ${colors.SECONDARY};
    }
  }
`;

export const Masthead = () => {
  const onClickHandler = () => {
    window.open('/resume.pdf', '_blank');
  };
  return (
    <div
      className="flex flex-col justify-center relative w-full h-screen bg-black-header bg-cover bg-no-repeat"
      style={{ backgroundImage: 'url("masthead-background.jpg")' }}
    >
      <div className="w-full h-full mx-auto text-center flex flex-col justify-center bg-black-opaque px-4 pt-12 sm:pt-0">
        <h1 className="max-w-md sm:max-w-lg md:max-w-xl mx-auto text-4xl sm:text-5xl md:text-6xl font-semibold text-center text-title-primary">
          Your search for a UI Engineer is over
        </h1>
        <p className="max-w-xl mt-4 mb-20 md:mb-4 md:mt-6 mx-auto mb-12 text-lg sm:text-xl md:text-2xl text-center text-white">
          Let&apos;s build something great together.
        </p>

        <div className="flex justify-center">
          <ButtonV1 variant="PRIMARY" className="mb-4" onClick={onClickHandler}>
            Download my resume
          </ButtonV1>
        </div>
        <StyledNavLink href="#about">
          More about me <CircleGuy>&gt;</CircleGuy>
        </StyledNavLink>
      </div>
    </div>
  );
};
