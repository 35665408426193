import * as React from 'react';

const breakpoints = {
  sm: '(min-width: 600px)',
  md: '(min-width: 960px)',
  lg: '(min-width: 1200px)',
};

export const useMediaQuery = (breakpoint: string) => {
  if (typeof window === 'undefined') {
    return false;
  }

  const query = window.matchMedia(breakpoints[breakpoint]);
  const [matches, setMatch] = React.useState(query.matches);

  React.useEffect(() => {
    const handleQuery = (e) => {
      setMatch(e.matches);
    };

    query.addListener(handleQuery);

    return () => {
      query.removeListener(handleQuery);
    };
  });

  return matches;
};
