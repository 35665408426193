import * as React from 'react';
import styled from 'styled-components';
import { colors } from 'utils/colors';
import { Speedometer } from 'icons/Speedometer';
import { Lock } from 'icons/Lock';
import { SvgResponsiveDesignSymbol } from 'icons/ResponsiveDesignSymbol';
import { OptimizationArrow } from 'icons/OptimizationArrow';
import { AccessibilityIcon } from 'icons/AccessibilityIcon';
import { GearsIcon } from 'icons/GearsIcon';
// import { SectionHeader } from 'components/SectionHeader';
import { routes } from 'models/routes';
import { useMediaQuery } from 'utils/useMediaQuery';
// import { SectionWrapper } from 'components/SectionWrapper/SectionWrapper';
import { SectionWrapper, SectionHeader, SectionDescription } from 'components';

const FeaturesGrid = styled.div`
  width: 100%;
  padding-top: 1rem;
`;

const FeatureItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const FeatureItem = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  width: 100%;
  height: 100%;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  @media screen and (min-width: 768px) {
    flex-direction: column;
    width: 33.33333%;
    padding: 1.5rem 4rem;
  }
`;

const StyledDescription = styled.p`
  width: 100%;
  font-size: 1rem;
  line-height: 1.4rem;
  color: rgba(0, 0, 0, 0.65);
  margin-top: 0.5rem;
  text-align: left;

  @media screen and (min-width: 768px) {
    font-size: 1.2rem;
    margin-top: 1rem;
    line-height: 1.8rem;
    text-align: center;
  }
`;

export const Featured = () => {
  const isMedium = useMediaQuery('md');
  const iconSize = isMedium ? 84 : 24;

  return (
    <SectionWrapper id={routes.routes.services.id}>
      <div className="mx-auto max-w-screen-xl">
        <SectionHeader className="text-left md:text-center">
          Whatever you need, I'll build it
        </SectionHeader>
        <SectionDescription className="mb-4 md:mx-auto md:mb-0 md:text-center">
          All of my projects are built with these features as{' '}
          <span className="border-0 border-b border-solid border-black-text font-semibold">
            first-class citizens
          </span>
        </SectionDescription>
        <FeaturesGrid>
          <FeatureItemContainer>
            <FeatureItem>
              {isMedium && <Speedometer size={iconSize} color={colors.PRIMARY} />}
              <h3 className="md:ml-0 md:mt-2 text-black-header md:text-black-text text-lg md:text-2xl font-semibold">
                Performance
              </h3>
              <StyledDescription>
                Don't let load times get between you and your users. Let performance be a priority
              </StyledDescription>
            </FeatureItem>
            <FeatureItem>
              {isMedium && <Lock size={iconSize} color={colors.PRIMARY} />}
              <h3 className="md:ml-0 md:mt-2 text-black-header md:text-black-text text-lg md:text-2xl font-semibold">
                Security
              </h3>
              <StyledDescription>
                Best security practices to protect your site against a variety of cyber attacks
              </StyledDescription>
            </FeatureItem>
            <FeatureItem>
              {isMedium && <OptimizationArrow size={iconSize} color={colors.PRIMARY} />}
              <h3 className="md:ml-0 md:mt-2 text-black-header md:text-black-text text-lg md:text-2xl font-semibold">
                Optimized for SEO
              </h3>
              <StyledDescription>
                High-ranking sites get more traffic. More traffic equals more customers
              </StyledDescription>
            </FeatureItem>
            <FeatureItem>
              {isMedium && <SvgResponsiveDesignSymbol size={iconSize} color={colors.PRIMARY} />}
              <h3 className="md:ml-0 md:mt-2 text-black-header md:text-black-text text-lg md:text-2xl font-semibold">
                Browser Agnostic
              </h3>
              <StyledDescription>
                If it can browse the web, it should be supported (yep, even you IE11)
              </StyledDescription>
            </FeatureItem>
            <FeatureItem>
              {isMedium && <AccessibilityIcon size={iconSize} color={colors.PRIMARY} />}
              <h3 className="md:ml-0 md:mt-2 text-black-header md:text-black-text text-lg md:text-2xl font-semibold">
                Accessibility
              </h3>
              <StyledDescription>
                Making the internet a better place for everyone starts with accessibility
              </StyledDescription>
            </FeatureItem>
            <FeatureItem>
              {isMedium && <GearsIcon size={iconSize} color={colors.PRIMARY} />}
              <h3 className="md:ml-0 md:mt-2 text-black-header md:text-black-text text-lg md:text-2xl font-semibold">
                Functionality
              </h3>
              <StyledDescription>
                I can't design a button to save my life, but I guarantee that button will
                do...something
              </StyledDescription>
            </FeatureItem>
          </FeatureItemContainer>
        </FeaturesGrid>
      </div>
    </SectionWrapper>
  );
};
