import * as React from 'react';

interface SvgProps {
  size: number;
  color: string;
}

export const SvgResponsiveDesignSymbol: React.FC<SvgProps> = ({ size, color, ...props }) => (
  <svg width={size} height={size} viewBox="0 0 48.832 48.832" fill={color} {...props}>
    <g>
      <g id="Layer_20_51_">
        <g>
          <polygon
            points="15.42,32.814 22.23,32.814 22.23,34.385 15.42,34.385 15.42,35.68 36.838,35.68 36.838,34.385 28.898,34.385 
				28.898,32.814 37.729,32.814 37.729,29.227 15.42,29.227 			"
          />
          <polygon
            points="9.489,11.231 41.957,11.231 41.957,24.209 44.739,24.209 44.739,8.449 6.707,8.449 6.707,17.14 9.489,17.14 			
				"
          />
          <path
            d="M0,39.799h14.523V18.013H0V39.799z M7.568,38.705c-0.386,0-0.7-0.312-0.7-0.699c0-0.386,0.314-0.699,0.7-0.699
				c0.387,0,0.699,0.312,0.699,0.699S7.955,38.705,7.568,38.705z M2.1,20.113h10.325l-0.001,16.072H2.1V20.113z"
          />
          <path
            d="M38.715,25.046v15.337h10.117V25.046H38.715z M43.979,25.68c0.121,0,0.22,0.101,0.22,0.221
				c0,0.121-0.099,0.222-0.22,0.222c-0.123,0-0.223-0.101-0.223-0.222C43.756,25.78,43.855,25.68,43.979,25.68z M42.665,26.416
				h2.623v0.212h-2.623V26.416z M43.891,39.721c-0.291,0-0.524-0.236-0.524-0.525c0-0.29,0.233-0.524,0.524-0.524
				c0.289,0,0.525,0.234,0.525,0.524C44.416,39.484,44.18,39.721,43.891,39.721z M47.369,37.915
				c-2.368,0.013-6.016,0.022-7.189,0.012V27.382l7.189-0.001V37.915z"
          />
        </g>
      </g>
    </g>
  </svg>
);
