import * as React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { colors } from 'utils/colors';

interface ButtonV1Props {
  variant?: 'PRIMARY' | 'SECONDARY' | 'GHOST' | 'GHOST_SECONDARY';
  height?: string;
  width?: string;
  onClick?: () => void;
  className?: string;
  type?: 'button' | 'input';
}

const ButtonStyles = {
  PRIMARY: css`
    color: ${colors.WHITE};
    background: ${colors.PRIMARY};

    &:hover {
      background: ${colors.DARK_PURPLE};
    }
  `,
  SECONDARY: css`
    color: ${colors.WHITE};
    background: ${colors.SECONDARY};

    &:hover {
      background: ${colors.TERTIARY};
    }
  `,
  GHOST: css`
    background: transparent;
    border: 1px solid ${colors.DARK};
    color: ${colors.WHITE};

    &:hover {
      border-color: ${colors.WHITE};
    }
  `,
  GHOST_SECONDARY: css`
    background: transparent;
    border: 3px solid ${colors.PRIMARY};
    color: ${colors.DARK};

    &:hover {
      background: ${colors.PRIMARY};
      color: ${colors.WHITE};
    }
  `,
};

const ButtonWrapper = styled.button<ButtonV1Props>`
  height: ${({ height = '50px' }) => height};
  width: ${({ width = '250px' }) => width};
  border: none;
  border-radius: 3px;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.15s ease-out;

  &:hover {
    transform: translateY(-1px);
    transition: all 0.15s ease-out;
  }

  ${({ variant = 'PRIMARY' }) => ButtonStyles[variant]};

  &.fade-enter {
    opacity: 0;
  }
  &.fade-enter-active,
  &.fade-enter-done {
    opacity: 1;
  }
  &.fade-exit {
    opacity: 1;
  }
  &.fade-exit-done {
    opacity: 0;
  }
`;

export const ButtonV1: React.FC<ButtonV1Props> = ({
  variant = 'PRIMARY',
  height = '50px',
  width = '250px',
  children,
  onClick,
  className = '',
  type = 'button',
}) => {
  if (type === 'button') {
    return (
      <ButtonWrapper
        variant={variant}
        height={height}
        width={width}
        onClick={onClick}
        className={className}
      >
        {children}
      </ButtonWrapper>
    );
  } else {
    return (
      <ButtonWrapper
        variant={variant}
        height={height}
        width={width}
        onClick={onClick}
        className={className}
        as="input"
        value="Send"
        type="submit"
      />
    );
  }
};
