import * as React from 'react';
// import styled from 'styled-components';

export const withStyles = (as: keyof JSX.IntrinsicElements = 'div', defaultClassNames) => ({
  className = '',
  children,
  ...props
}) => {
  const Tag = as;
  return (
    <Tag className={`${defaultClassNames} ${className}`} {...props}>
      {children}
    </Tag>
  );
};

// interface TailwindPrimitiveProps {
//   className?: string;
//   as?: keyof JSX.IntrinsicElements;
// }

export const SectionWrapper = withStyles(
  'section',
  'my-4 mx-auto p-4 max-w-screen-xl flex flex-col items-center md:my-12 md:pt-4',
);

// const SectionWrapper = styled.section`
//   margin: 1rem auto 1rem;
//   padding: 1rem;
//   max-width: 1200px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;

//   @media screen and (min-width: 768px) {
//     margin: 3rem auto 3rem;
//     padding: 4rem 1rem 1rem;
//   }
// `;
